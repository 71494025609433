<script setup lang="ts">
import Panel from "./Panel.vue";
import Button from "@/components/ui/Button.vue";

const styleString = {
	'--padding': '90px 270px'
}

</script>
<template>
	<Panel
		bgPosition="center"
		hover
		bg="/img/corrigo-kv02-v01_textless.jpg"
		overlay="rgba(255, 255, 255, 0.08)"
		class="text-white"
		wrapperClass="flex flex-col gap-4"
		:style="styleString"
	>
		<p>
			<h2 class="header-responsive-medium">Geodézia</h2>
		</p>
		<p class="text-xl paragraph-responsive">
			RTK jelszolgáltatás geodéziai-földmérési munkához, legyen az kataszter,{{"\n"}}
			tervezési térkép készítése, terepfelvétel, AKG mérés, vagy GNSS mérés.
		</p>		
		<router-link :to="{name: 'srv-geod'}">
			<Button>
				bővebben
			</Button>		
		</router-link>
	</Panel>
	<Panel
		bgPosition="center"
		hover
		bg="/img/corrigo-kv03-v02_textless.jpg"
		overlay="rgba(255, 255, 255, 0.05)"
		class="text-white"
		wrapperClass="flex flex-col gap-4"
		:style="styleString"
	>
		<p>
			<h2 class="header-responsive-medium">Mezőgazdaság</h2>
		</p>
		<p class="text-xl paragraph-responsive">
			Centiméteres pontosságú RTK korrekciós jelszolgáltatás önvezető traktorokhoz{{"\n"}}
			és egyéb mezőgazdasági gépekhez a lehető legkorszerűbb munkavégzéshez.
		</p>		
		<router-link :to="{name: 'srv-agro'}">
			<Button>
				bővebben
			</Button>		
		</router-link>
	</Panel>
	<Panel		
		hover
		bg="/img/corrigo-kv03-v03_bg-3.jpg"
		overlay="rgba(0, 0, 0, 0.25)"
		class="text-white"
		wrapperClass="flex flex-col gap-4"
		:style="{
			...styleString,
			backgroundPositionY: '-500px'
		}"
	>
		<p>
			<h2 class="header-responsive-medium">Önvezető járművek,<br/>drónok</h2>
		</p>
		<p class="text-xl paragraph-responsive">
			A fantázia a szemünk előtt válik valósággá: szolgáltatásunknak{{"\n"}}
			köszönhetően az önvezető autók és drónok egyre nagyobb teret nyerhetnek.
		</p>			
		<router-link :to="{name: 'srv-drones'}">
			<Button>
				bővebben
			</Button>
		</router-link>
	</Panel>
</template>
<style lang="scss">
	h2 {
		@apply text-6xl font-bold;
	}

</style>