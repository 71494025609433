<script setup lang="ts">
const props = defineProps<{
	data: object
	labels?: {
		[K: string]: string
	}
	labelClass?: string
}>()

const items = props.labels
	? Object.entries(props.labels).map(([key, value]) => {
			return {
				key: key,
				label: value,
				value: props.data[key]
			}
	  })
	: Object.entries(props.data).map(([key, value]) => {
			return {
				key: key,
				label: key,
				value: value
			}
	  })
</script>
<template>
	<ul class="data-list">
		<li v-for="{ key, label, value } in items" :key="key" class="data-list-entry">
			<strong class="data-list-label" :class="labelClass">{{ label }}:</strong>
			<span class="data-list-item">
				<slot v-if="$slots[key]" :name="key" :value="value" :$v="value" :context="data" />
				<template v-else>
					{{ value }}
				</template>
			</span>
		</li>
	</ul>
</template>
<style lang="scss">
.data-list {
	@apply flex flex-col;
	@apply gap-1;

	li {
		@apply flex gap-2;
	}

	&.grid {
		display: grid;
		grid-template-columns: auto auto;
		gap: var(--gap, inherit);

		li {
			display: contents;
		}
	}
}
</style>
