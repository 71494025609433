import dayjs from "dayjs"

interface FormDef {
	group?: string
	groupKey: string
	label: string
	name: string
	required?: boolean
	type?: string
	index?: number
	pattern?: string
	autocomplete?: string
	bottomMargin?: boolean
	bottomText?: string
	options?: {
		label: string
		value: string
	}[]
	attrs?: any
}

export const formDefinitions: { [K: string]: FormDef[] } = {
	client: [
		{
			group: "Kapcsolattartó adatai",
			groupKey: "client",
			label: "Vezetéknév",
			name: "lastName",
			required: true,
			index: 10,
			autocomplete: "family-name"
		},
		{
			groupKey: "client",
			label: "Keresztnév",
			name: "firstName",
			required: true,
			index: 10,
			autocomplete: "given-name"
		},
		{
			groupKey: "client",
			label: "E-Mail",
			name: "email",
			required: true,
			index: 20,
			type: "email",
			autocomplete: "email"
		},
		{
			groupKey: "client",
			label: "Telefonszám",
			name: "tel",
			type: "tel",
			required: true,
			index: 25,
			autocomplete: "tel"
		},
		{
			groupKey: "client",
			label: "Irányítószám",
			name: "postalCode",
			required: true,
			pattern: "/d+/",
			index: 30,
			autocomplete: "postal-code"
		},
		{
			groupKey: "client",
			label: "Város",
			name: "city",
			required: true,
			index: 28,
			autocomplete: "address-level2"
		},
		{
			groupKey: "client",
			label: "Utca, házszám",
			name: "address",
			required: true,
			index: 50,
			autocomplete: "street-address"
		},
		{
			groupKey: "client",
			label: "Földmérő igazolványszám",
			name: "licenseNumber",
			required: true,
			index: 70,
			bottomMargin: true
		}
	],
	billing: [
		{
			groupKey: "billing",
			group: "Számlázási adatok",
			label: "Név / cégnév",
			name: "name",
			required: true,
			index: 80,
			autocomplete: "billing name"
		},
		{
			groupKey: "billing",
			label: "Irányítószám",
			name: "postalCode",
			required: true,
			pattern: "/d+/",
			index: 30,
			autocomplete: "billing postal-code"
		},
		{
			groupKey: "billing",
			label: "Város",
			name: "city",
			required: true,
			index: 28,
			autocomplete: "billing address-level2"
		},
		{
			groupKey: "billing",
			label: "Utca, házszám",
			name: "address",
			required: true,
			index: 50,
			autocomplete: "billing street-address"
		},
		{
			groupKey: "billing",
			label: "Adószám / adóazonosító",
			name: "taxCode",
			required: true,
			index: 120,
			bottomMargin: true
		}
	],
	details: [
		{
			groupKey: "details",
			group: "Szolgáltatás",
			label: "Viszonteladó",
			name: "seller",
			required: true,
			options: [
				{
					label: "FORGEO Kft.",
					value: "FORGEO Kft."
				},
				{
					label: "GeoMentor Kft.",
					value: "GeoMentor Kft."
				},
				{
					label: "3D Geosolutions Hungary Kft.",
					value: "3D Geosolutions Hungary Kft."
				},
				{
					label: "Infobex Kft.",
					value: "Infobex Kft."
				}
			]
		},
		{
			groupKey: "details",
			label: "Igénybe vett szolgáltatás",
			name: "service",
			required: true,
			index: 130,
			options: [
				{
					label: "30 napos",
					value: "30 napos"
				},
				{
					label: "365 napos",
					value: "365 napos"
				},
				{
					label: "365 napos internet szolgáltatással",
					value: "365 napos internet szolgáltatással"
				}
			]
		},
		{
			groupKey: "details",
			label: "Darabszám",
			name: "count",
			required: true,
			type: "number",
			index: 140,
			bottomText:
				"5-nél nagyobb darabszám esetén kérje egyedi árajánlatunkat!\nEzt kérjük, hogy a Megjegyzés rovatba tűntesse fel.",
			attrs: {
				min: 1,
				max: 5
			}
		},
		{
			groupKey: "details",
			label: "Kezdődátum",
			name: "startDate",
			required: true,
			type: "date",
			attrs: {
				min: dayjs().format("YYYY-MM-DD"),
				max: dayjs().add(1, "year").format("YYYY-MM-DD")
			}
		},
		{
			groupKey: "details",
			label: "Megjegyzés",
			name: "comments",
			// required: true,
			attrs: {
				style: "border-radius: 1rem; align-items:baseline",
				maxLength: "1000",
				placeholder: "Max. 1000 karakter",
				is: "textarea",
				rows: 4
			}
		}
	]
}

const { client, details, billing } = formDefinitions

export const all = [...client, ...billing, ...details]
