import { clamp } from "../../utils/clamp"

export function getPagination(item_count: number, page_size: number, page = 0) {
	const totalPages = Math.ceil(item_count / page_size)
	const currentPage = clamp(page, 0, totalPages - 1)
	const startIndex = currentPage * page_size
	const endIndex = Math.min(startIndex + page_size, item_count)

	return {
		totalPages,
		currentPage,
		startIndex,
		endIndex
	}
}
