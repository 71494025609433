<script setup lang="ts">
import Icon from "./Icon.vue"
const props = defineProps<{
	id?: string
	label?: string
	modelValue?: boolean | undefined | null
	readonly?: boolean
	onChange?: () => any
}>()

const elementId = props.id ?? Math.random().toString(16).slice(2)

const emit = defineEmits(["update:modelValue"])

const updateValue = (event: boolean) => {
	if (props.readonly) return
	emit("update:modelValue", event)
	if (props.onChange) props.onChange()
}

const handleClick = (e: Event) => {
	updateValue(!props.modelValue)
	// console.log(props.modelValue)
}
</script>
<template>
	<div class="tw-checkbox" @click="handleClick" tabindex="0">
		<span class="checkbox-icon" :class="{ checked: modelValue }" :id="elementId">
			<Icon icon="check" v-show="modelValue" />
		</span>
		<label v-if="label" :for="elementId">
			{{ label }}
		</label>
	</div>
</template>
<style lang="scss">
.tw-checkbox {
	@apply flex items-center;
	@apply gap-2;

	&:active .checkbox-icon {
		@apply ring-2 ring-primary ring-opacity-50;
	}
}

.checkbox-icon {
	@apply border-2 border-neutral-600;
	@apply box-border;
	@apply transition-all;
	@apply inline-block;
	@apply rounded-lg;
	// @apply ring ring-primary-600 ring-opacity-50;
	text-align: center;
	line-height: 20px;
	width: 24px;
	height: 24px;
	// font-weight: 600;

	&.checked {
		@apply bg-primary border-primary;
	}

	i {
		@apply text-white;
	}
}
</style>
