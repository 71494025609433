<script setup lang="ts">
import IonosphereChart from "@/components/app/network-map/IonosphereChart.vue"
// import { StationInfo } from "@api/models"
import { onMounted, onUpdated, provide, ref, watch, watchEffect } from "vue"
import StationMap from "../components/app/network-map/StationMap.vue"
import toGeojson from "../components/app/network-map/toGeojson"
import { CMS } from "../api/cms"
import StationInfo from "../components/app/network-map/StationInfo.vue"
import { useRouter } from "vue-router"
import { nextTick } from "vue"
import Icon from "@/components/ui/Icon.vue"

const stations = toGeojson(await CMS.getStations())

const router = useRouter()

async function scrollToHash(hash: string) {
	if (!hash) return

	console.log(hash)
	await nextTick()

	const element = document.getElementById(hash.substring(1))
	// console.log(element)

	if (element) {
		await nextTick()
		element.scrollIntoView({ behavior: "smooth" })
	}
}
onMounted(() => {
	scrollToHash(router.currentRoute.value.hash)
})

watch(
	() => router.currentRoute.value.hash,
	() => {
		scrollToHash(router.currentRoute.value.hash)
	}
)
</script>
<template>
	<main class="page">
		<!-- <RouterView /> -->
		<StationMap :stations="stations" :selected="$route.params.stationCode as string ?? null" />
		<StationInfo
			:station="stations.features.find(st => st.properties.stationCode === $route.params.stationCode)?.properties"
		/>

		<ul class="bg-transparent absolute top-2 right-2 z-5 flex flex-col gap-2">
			<RouterLink class="bookmark" :to="{ hash: '#i95' }">
				<Icon icon="chart-simple" />
				<span>I95 Index</span>
			</RouterLink>
			<RouterLink class="bookmark" :to="{ hash: '#residual' }">
				<Icon icon="chart-simple" />
				<span>IRIM / GRIM</span>
			</RouterLink>
		</ul>

		<iframe id="i95" class="chart-embed" src="https://portal.corrigo.hu/embed/i95-history" />
		<iframe id="residual" class="chart-embed" src="https://portal.corrigo.hu/embed/residual-history" />

		<!-- <div class="bg-white text-neutral-900 p-24"> -->
		<!-- <IonosphereChart /> -->
		<!-- </div> -->
	</main>
</template>
<style lang="scss">
.bookmark {
	@apply bg-black hover:bg-opacity-80 bg-opacity-70;
	@apply backdrop-blur-lg;
	@apply px-2 py-1;
	@apply rounded;
	@apply shadow;
	@apply text-white;
	@apply flex gap-2 items-center;

	font-weight: 00;
	// font-family: sans-serif;
	// line-height: normal;
}

.chart-embed {
	@apply w-full flex-shrink-0;
	height: 100%;
	background-color: white;
}

main.page {
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	position: relative;
}

#full-size-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

#network-map {
	@apply bg-white;
	// @apply flex items-center justify-center;
	height: 100%;
	flex-grow: 1;
	flex-shrink: 0;
}
</style>
