<script setup lang="ts">
import { reactive, ref } from "vue"
import { CMS } from "../../api/cms"
import Button from "../ui/Button.vue"
import { getBanner } from "@api/apis/newsApi"

const props = defineProps<{
	articleId?: number
}>()

const data = await CMS.getActiveBanner()

// console.log(banner)
</script>
<template>
	<div
		class="banner"
		v-if="data.articleId"
		:style="{
			color: data.textColor,
			backgroundColor: data.bgColor
		}"
	>
		<!-- <slot /> -->
		{{ data.title }}
		<RouterLink :to="{ name: 'article', params: { articleId: data.articleId } }" class="ml-8">
			<Button size="lg"> részletek </Button>
		</RouterLink>
	</div>
</template>
<style lang="scss">
.banner {
	@apply px-12 py-3;
	@apply flex justify-center items-center gap-2;
	position: sticky;
	top: 0;
	z-index: 9999;

	// @apply bg-primary-dark;

	strong {
		@apply font-bold;
	}
	@apply text-white;
	@apply text-2xl;
	@apply text-center;
	// background: radial-gradient(circle, hsl(160 70% 25%) 0%, hsl(160 70% 30%) 100%);
	font-weight: 300;
	@apply shadow-xl;
	// @apply backdrop-blur backdrop-filter;
	// background-position: center bottom;
	// background: url('/img/corrigo-kv02-v01_textless_2.jpg')
}
</style>
