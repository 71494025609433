export interface ArticleConf {
	title: string
	url?: string
	background: {
		src?: string
		color?: string
	}
	colorPrimary?: string
	colorSecondary?: string
	html?: string
	routeName?: string
	rootStyle?: any
	pageBg?: { url: string }
	style?: any
}

export const STATIC_ARTICLES: ArticleConf[] = [
	{
		title: "Geodézia",
		url: "geodezia",
		routeName: "srv-geod",
		background: {
			src: "/img/corrigo-kv02-v01_textless.jpg"
		},
		colorSecondary: "0 50 71",
		html: /*html*/ `
		<h6 class='text-primary font-bold'>
			A CORRIGO egyedülálló, 4 műholdrendszert alkalmazó műholdas helymeghatározással nyújt támogatást a geodézia feladatok elvégzéséhez, a mérnökgeodéziában és a kataszterben egyaránt.
		</h6>
		<p>
			A földmérés kezdetét nehéz meghatározni, ennek ellenére leszögezhető, hogy évezredek óta létező tudomány. A mérésmódszerek folyamatosan fejlődtek, újabb és újabb eszközöket használva. A digitalizációnak köszönhetően egyre több területen használják szerteágazó feladatokhoz, újabb és újabb részterületekkel bővülve. A hagyományosnak számító mérési módszerek nem vesztek el a technológia fejlődésével, de sok feladatnál hatékonyabbá és könnyebbé tették a munkát a technológia új vívmányai. A CORRIGO multikonstellációs (GPS, Glonass, Galileo, BeiDou) RTK (VRS) szolgáltatása tökéletesen alkalmazható bármilyen geodéziai-földmérési munkához (kataszter, tervezési térképkészítés, terepfelvétel, AKG mérés stb.)
		</p>
		<p>
			<strong class="block -mb-4">Geodéziai felhasználáshoz szerződött, együttműködő szakmaipartnerünk a FORGEO Kft!</strong>
			A FORGEO Kft. biztosítja a szükséges szakmai hátteret, heti rendszerességgel elvégzi a bázisállomások ellenőrző számítását. Közös munkánk garantálja a szolgáltatásunk elvárt geodéziai és jogszabályi színvonalát!
		</p>
		<h5 class='text-secondary font-bold'>CORRIGO szolgáltatásunkat geodéziai célra értékesítő partnereink:</h5>
		<ul class='border-list border-secondary'>
			<li>
				<p>
					<a href='https://forgeo.hu'><strong>FORGEO Kft.:</strong></a> RUIDE, FOIF, E-Survey, Leica, Topcon, Sokkia, Geomax, CHC, Stonex, Emlid és South eszközökhöz, illetve ezeken kívül egyéb gyártók készülékei.
				</p>
			</li>
			<li>
				<p>
					<a href='https://geomentor.hu'><strong>GeoMentor Kft.:</strong></a> Javad, SatLab, Hi-Target, Carlson eszközökhöz
				</p>
			</li>
			<li>
				<p>
					<a href='https://www.3dgeosolutions.hu'><strong>3D Geosolutions Hungary Kft.:</strong> </a> Trimble eszközökhöz
				</p>
			</li>
		</ul>`
		// html: '<div/>'
	},
	{
		title: "Mezőgazdaság",
		url: "mezogazdasag",
		routeName: "srv-agro",
		background: {
			src: "/img/corrigo-kv03-v02_textless.jpg"
		},
		html: "<p>A 2000-es évek elején a mezőgazdaságot is elérte az informatika. Napjainkban –úgymint számos más terület is – az IT nagyban segíti az agrárium fejlődését. Ez a terület - többek között a klímaváltozás és a népességnövekedés miatt - különösen igényli a nagymértékű fejlesztéseket. Korunk legnagyobb kihívásaira a technológiai fejlőfdés nyújthat megoldást.</p><h6 class='text-primary font-bold'>A precíziós gazdálkodás egyik alappillére a centiméterpontos helymeghatározás.</h6><p>Korábban 5 cm-es átfedéssel történő szántással érveltünk a precíziós gazdálkodás mellett. Manapság – többek között a Corrigo 4 műholdrendszert alkalmazó helymeghatározási rendszerének köszönhetően – viszont már nem számít szenzációnak, hogy lehetséges iránybaforgatva a talajba, centiméter-pontosan elvetni a kukoricamagot. Egy ilyen műveletnél óriási jelentősége van a 8-10 milliméteres helymeghatározásnak.</p><h5 class='color-primary font-bold'>További információkért és ajánlatkéréshez keressen minket bizalommal!</h5>"
		// html: '<div/>'
	},
	{
		title: "Önvezető járművek,\ndrónok",
		url: "dronok",
		routeName: "srv-drones",
		background: {
			src: "/img/corrigo-kv03-v03_bg-3.jpg"
		},
		colorPrimary: "65 155 211",
		html: "<p>A repülőgépeknél már több évtizede alkalmazzák a robotpilóta-rendszereket. Sci-fi regényekben és filmekben már olvashattuk és láthattuk a pilóta nélküli autókat, de ezek sokáig tényleg csak az alkotók fantáziáinak szüleményei voltak.</p><h6 class='text-primary font-bold'>A technológia fejlődésével szépen lassan a sci-fi valósággá válik.</h6><p>Ma már mi magunk is utazhatunk olyan – jellemzően amerikai fejlesztésű – járművekkel, amelyek már valóban tudnak – körülményektől függően – emberi beavatkozás nélkül közlekedni. 2020. óta mi is aktív részesei vagyunk az önvezető járművekkel kapcsolatos legmodernebb kutatásoknak és fejlesztéseknek.</p><h5 class='text-primary font-bold'>További információkért és ajánlatkéréshez keressen minket bizalommal!</h5>"
		// html: '<div/>'
	},
	{
		title: "GNSS szakmai nap",
		routeName: "zalazone",
		url: "gnss_nap",
		background: {
			color: "hsl(215deg 60% 20%)"
		},
		colorSecondary: "220 220 220",
		pageBg: {
			url: "/img/corrigo-kv02-v01_textless.jpg"
		},
		rootStyle: {
			"--h": "auto"
		},
		style: {
			backdropFilter: "blur(24px)",
			backgroundColor: "#fff3",
			maxWidth: "1360px",
			marginInline: "auto",
			color: "white"
		},
		html: `<div class="text-center">
		<h6>Sok szeretettel meghívjuk Önt a CORRIGO csapat által rendezett</h6>
		<h5><strong>GNSS szakmai napra</strong></h5>
	</div>
	<h4 align="center" class="text-xl">
		<strong>Dátum: 2022. október 12. 08:30-16:00</br>Helyszín: ZalaZONE Járműipari Tesztpálya<br/>8900 Zalaegerszeg, ZalaZONE tér 1.</strong>
	</h4>
	<div align="center">
		<p align="center" class="paragraph-responsive text-center" style="text-align:center; margin-block:1em">Az egész napos programunk célja áttekintést adni a hazai GNSS alkalmazásának lehetőségeiről, fókuszban a geodéziai és az új iparágak bemutatásával.
			Napunkat a mérnöki idegenvezetéssel történő Járműipari tesztpálya körbejárásával zárjuk.

			Programunkon a részvétel <strong>DÍJMENTES</strong>.

			A regisztráltak száma elérte a tervezett létszámot, <strong>további regisztráció nem lehetséges.</strong>
		</p>
		<!-- <a style="text-decoration: underline;"  style="color:white">
			<button class="button xl">regisztráció</button>
		</a> -->
	</div>	
	<h5>A rendezvény előadói közt jelen lesznek:</h5>
	<ul class="w-auto text-xl flex gap-4 flex-col ml-7">
		<li>• Űrkutatásért felelős miniszteri biztos</li>
		<li>• Miniszterelnökség Ingatlannyilvántartási és Térképészeti Főosztály képviselője</li>
		<li>• Zala Megyei Kormányhivatal Földhivatali Főosztályának vezetője</li>
		<li>• Zalaegerszeg alpolgármestere</li>
		<li>• Magyar Mérnöki Kamara elnöke</li>
		<li>• BME Építőmérnöki karának dékánja és egyetemi docense</li>
		<li>• Geodéziai, önvezetőjármű fejlesztői, drónozási- és agrár szakemberek.</li>
	</ul>
	<a target="_blank" style="text-decoration: underline;" href="/docs/GNSS_szakmai_nap_program.pdf" style="color:white" class="self-center">
		<button class="button xl">program</button>
	</a>
	
		A rendezvény akkreditációja a Lechner Tudásközpont által folyamatban, az ingatlanrendező-földmérők és geodéziai tervezők részére várhatóan 3 IRM és GDT továbbképzési pont jár. <br/>
	<br/>
	<div align="center" >Szervező:<br/> CORRIGO, Magyarország „rugalmas” RTK korrekció szolgáltatója és legkorszerűbb RTK hálózatának üzemeltetője 	
	</div>
	</div>`
	}
]
