<script setup lang="ts">
import dayjs from "dayjs"
import { CMS } from "@/api/cms"
import { marked } from "marked"
import { useHead } from "@unhead/vue"
import { onBeforeUnmount, onUnmounted } from "vue"
import ArticleHeader from "./article-header.vue"

const props = defineProps<{
	articleId: number
}>()

const {
	data: { attributes: attr, id },
	meta
} = await CMS.loadArticle(props.articleId)

const img = attr.indexImage.data.attributes
// const img = formats.large ?? formats.medium ?? formats.small

const html = marked.parse(attr.content)

const pageHead = useHead(
	{
		title: `Corrigo - ${attr.title}`,
		meta: [
			{
				name: "description",
				content: attr.summary
			}
		]
	},
	{
		mode: "client"
	}
)

onBeforeUnmount(() => {
	pageHead?.dispose()
})
</script>
<template>
	<article
		class="cms-article"
		:style="{
			//@ts-ignore
			'--c-primary': attr.colorPrimary,
			'--c-secondary': attr.colorSecondary
		}"
	>
		<article-header
			:img-url="img.url"
			:title="attr.title"
			:summary="attr.summary"
			:date="attr.date"
			:blur-factor="2"
		/>
		<!-- <header class="cms-article-header">
			<img :src="img.url" />
			<div>
				<h4 class="article-title">
					{{ attr.title }}
				</h4>

				<h5 class="article-summary">
					{{ attr.summary }}
				</h5>
				<span class="date">
					{{ dayjs(attr.publishedAt).format("YYYY. MMMM D.") }}
				</span>
			</div>
		</header> -->
		<main class="cms-article-body" v-html="html" />
	</article>
</template>
<style lang="scss">
.cms-article {
	@apply bg-neutral-50;
	@apply relative;
	@apply flex flex-col gap-0 items-stretch;
	z-index: 0;

	//defaultSizes:
	--size: 18px;

	--gutter-l: 16em;
	--gutter-r: 24em;
	--pad-y-1: 6em;
	--pad-y-2: 2em;

	--text-sm: 0.825em;
	--text-md: 1em;
	--text-lg: 1.25em;
	--text-xl: 2.5em;
	font-size: var(--size);

	@media (max-width: 1535px) {
		--gutter-l: 128px;
		--gutter-r: 128px;
	}
	@media (max-width: 1279px) {
		--size: 16px;
		--text-xl: 32px;
		--gutter-l: 128px;
		--gutter-r: 128px;
	}
	@media (max-width: 1023px) {
		--text-xl: 24px;
		--gutter-l: 96px;
		--gutter-r: 96px;
	}
	@media (max-width: 767px) {
		--size: 14px;
		--gutter-l: 24px;
		--gutter-r: 24px;
	}
	@media (max-width: 639px) {
	}
}

.cms-article-body {
	@apply bg-neutral-50;
	@apply text-neutral-800;
	margin-left: var(--gutter-l);
	margin-right: var(--gutter-r);
	padding-block: var(--pad-y-2);
	text-align: justify;
	@apply flex flex-col gap-4;
	// @apply bg-white;
	// @apply shadow-md;
	//default-sizes
	p {
		text-align: justify;
		font-size: var(--text-lg);
		line-height: normal;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: var(--text-xl);
		font-weight: 500;
		color: inherit;
		// color: rgba(var(--c-primary) / 100%);
		margin-block: var(--text-sm);
	}
}
</style>
