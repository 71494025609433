export const GLOSSARY = {
	GNSS: "Global Navigation Satellite System – Globális műholdas navigációs rendszer. A műholdas helymeghatározó rendszerek összefoglaló elnevezése.",

	GPS: "Global Positioning System. Az Amerikai Egyesült Államok által üzemeletetett műholdas helymeghatározó rendszer.",

	GLONASS: "az Orosz állam által üzemeletetett műholdas helymeghatározó rendszer.",

	Galileo:
		"az Európai Unió kezdeményezésére, az Európai Űrügynökség által létre hozott, és üzemeltetett műholdas helymeghatározó rendszer.",

	BeiDou: "az Kínai állam által üzemeletetett műholdas helymeghatározó rendszer.",

	CORS: "Continuously Operating Reference Stations - geodéziai minőségű GNSS vevőkészülékek és antennák, amelyek fixen telepített állomások, ismert helyen. Ezek az állomások folyamatosan gyűjtik a GNSS adatokat, és továbbítják ezeket interneten keresztül egy központi szerverre.",

	RTCM: "Radio Technical Commission for Maritime Services - egy szabvány, amely meghatározza az adatok struktúráját különféle differenciális korrekciós alkalmazásokhoz.",

	"RTCM3.1":
		"hét RTCM üzenettel transzformációs algoritmusokat és adatstruktúrákat határoznak meg, amelyek lehetővé teszik, hogy megfelelő RTCM transzformációs üzeneteket továbbítsanak a GNSS szolgáltatás felhasználójának. Támogatott rendszerek GPS és Glonass.",

	"RTCM3.2 - MSM":
		"Multiple Signal Message- az RTCM szabvány alacsonyabb verziójú adatstruktúráját foglalja magába, és lehetővé teszi több konstelláció egyidejű használatát. Az MSM jelenleg GPS-t, GLONASS-t, Galileo-t, BeiDou-t, QZSS-t (Japán), SBAS-t támogatja, L1, L2 és L5 frekvenciákon.",

	NTRIP: "Networked Transport of RTCM via Internet Protocol - az RTCM hálózati kapcsolata, internetes protokollon keresztül Egy alkalmazásszintű protokoll, amely támogatja a globális navigációs műholdrendszer (GNSS) adatainak interneten keresztüli továbbítását. Az NTRIP protokoll lehetővé teszi, hogy a rover (GNSS vevő) hozzáférjen az RTK bázisállomás adataihoz.",

	"4GNSS": "jelen esetben a GPS+GLONASS+Galileo+BeiDou műholdrendszerek összefoglaló elnevezése.",

	"4GNSS hálózat": "több CORS egyetlen NTRIP casterbe történő bekötése.",

	RTK: "Real Time Kinematic – valós idejű műholdas helymeghatározás.",

	Rover: "GNSS vevőkészülék, amely képes műhold jelek észlelésére és azok alkalmazásával helymeghatározásra, akár egy helyben állva, akár mozgás közben. Jelen esetben a rover fogalmába együttesen értjük a hardver és szoftvert. A rovert szokták NTRIP-kliensnek, a „terepen lévő” GNSS eszköznek is nevezni.",

	Caster: "egy cél szoftver, amely a CORS-ok műhold észleléseit dolgozza fel és továbbítja a rover felé.",

	VRS: "Virtual Reference Station – virtuális referencia állomás. A VRS koncepciót Lambert Wanninger fejlesztette ki 1997-ben. Eszerint a mobil vevőkészülék (rover) először elküldi a földrajzi helyzetének hozzávetőleges koordinátáit a caster-be, majd ehhez - meghatározott távolságban - a program kijelöl egy fix pontot. A rendszer erre a pontra folyamatosan kiszámítja a műholdészleléseket, emellett lokalizált mérési eredményeket vagy korrekció számításokat generál erre a virtuális bázisállomásra, majd ezeket az adatokat továbbítja a rovernek.",

	"VRS hálózat":
		"rendszerünk alapelve az, hogy a korrekciós adatok kiszámításához több referenciaállomás adatait interpoláljuk. Több állomás adatainak felhasználása növeli a számítás pontosságát, ami döntően csökkenti az RTK mérés pontatlanságát okozó tényezők hatásait (különböző légkör típusok, a műholdak pályaelhagyásából eredő hibás működése, egyéb – pl. visszaverődő - jelek észlelése). Nemcsak a fizikai referenciaállomás és a rover közötti megengedett távolság növelhető, hanem a rendszer is megbízhatóbbá válik. Több előnye van az egybázisos rendszerrel szemben a mérési pontosság növekszik, mivel a rover-bázis távolság kismértékű, illetve, ha egy referenciaállomás átmenetileg meghibásodik, a korrekciós adatokat a környező referenciaállomások adataiból együttesen számítja ki a caster.",

	"NTRIP caster szerver":
		"alapvetően egy http kiszolgáló, amely kezeli a differenciális korrekciós források, például a VRS-hálózatok hitelesítését és jelszavas vezérlését, és továbbítja a javításokat. Az NTRIP az RTCM Networked Transport of Internet Protocol protokollon keresztüli rövidítése. Az NTRIP jelentése: az RTCM hálózatban történő továbbítása internet protokollon keresztül.",

	NMEA: "National Marine Electronics Association (Nemzeti Tengeri Elektronikai Szövetség) által meghatározott adatformátum, amelyet GNSS berendezések kommunikációjához alkalmaznak. Az NMEA üzenetek GNSS adatokat továbbítanak egy „beszélő” eszközről egy „figyelő” eszközre (vagy több eszközre). A pozícióadatokat tartalmazó szabványos NMEA üzenetet GGA-nak hívják, és több mezőt tartalmaz, beleértve a 3D koordinátákat, a megoldás állapotát, a felhasznált műholdak számát és egyéb adatokat.",

	"Mount point":
		"Csatlakozási pont - Az NTRIP Caster címe és portja. A rover ehhez a ponthoz csatlakozik az adatok küldéséhez és fogadásához."
}
