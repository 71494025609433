<script setup lang="ts">
import Article from "../Article.vue"
import Input from "../../components/ui/Input.vue"
import { computed, reactive, ref, toRaw } from "vue"
import Select from "../../components/ui/Select.vue"
import Button from "../../components/ui/Button.vue"
// import Icon from "../components/ui/Icon.vue"

import { all as formDefs } from "./form"
import Checkbox from "../../components/ui/Checkbox.vue"
import RadioGroup from "../../components/ui/RadioGroup.vue"

// const {client, }

//const API_URL = "http://localhost:6100/api/place_order"
const API_URL = "https://portal.corrigo.hu/api/place_order"

const formState = reactive(
	formDefs.reduce(
		(obj, cur) => {
			obj[cur.groupKey][cur.name] = ""
			return obj
		},
		{
			client: {},
			billing: {},
			details: {}
		}
	)
)

const isValidated = ref(false)

// const disabled = computed(() => {
// 	return formDefs.some((f) => f.required && formState[f.groupKey][f.name] == "")
// })

async function handleSubmit(e) {
	isValidated.value = true
	e.preventDefault()

	loading.value = true
	try {
		console.log(formState)
		await fetch(API_URL, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(formState)
		})
		successful.value = true
		window.scrollTo({ top: 0, behavior: "smooth" })
	} finally {
		loading.value = false
	}
}

const loading = ref(false)
const successful = ref(false)
const accepted = ref(false)
</script>
<template>
	<Article
		style="--h: 280px"
		:article="{
			title: 'Megrendelés',
			background: {
				src: '/img/bg01.jpg'
			}
		}"
	>
		<form
			id="order-form"
			@submit="handleSubmit"
			:class="{ validated: isValidated }"
			class="relative"
			autocomplete="on"
		>
			<template v-for="(formItem, i) of formDefs" :key="i">
				<h6 v-if="formItem.group" class="section-header">
					{{ formItem.group }}
				</h6>
				<label :for="formItem.name">
					{{ formItem.label }}:
					<span v-if="formItem.required" class="asterisk">*</span>
				</label>
				<RadioGroup
					v-if="formItem.options"
					:options="formItem.options"
					:id="formItem.name"
					:name="formItem.name"
					v-model="formState[formItem.groupKey][formItem.name]"
					:disabled="loading || successful"
					:required="formItem.required"
				>
					<!-- <template #suffix v-if="validate(formItem.name)">
						<Icon icon="circle-check"/>
					</template> -->
				</RadioGroup>
				<Input
					v-else
					:id="formItem.name"
					:placeholder="formItem.label"
					:name="formItem.name"
					:required="formItem.required"
					v-model="formState[formItem.groupKey][formItem.name]"
					:type="formItem.type"
					:disabled="loading || successful"
					:autocomplete="formItem.autocomplete ?? false"
					v-bind="formItem.attrs"
				>
					<!-- <template #suffix v-if="validate(formItem.name)">
						<Icon icon="circle-check"/>
					</template> -->
				</Input>
				<span
					v-if="formItem.bottomText"
					style="grid-column: 2 / -1; margin-top: -0.5rem"
					class="text-sm pl-4 mb-4 whitespace-pre-line"
				>
					{{ formItem.bottomText }}
				</span>
			</template>

			<span class="flex items-center gap-4" style="grid-column: 1 / -1">
				<Checkbox v-model="accepted" />
				<p>
					Elolvastam és elfogadom az
					<a href="/docs/corrigo_%C3%A1szf_2022-08-31.pdf" target="_blank" class="text-primary underline"
						>Általános Szolgáltatási Feltételeket</a
					>
				</p>
			</span>

			<Button
				class="self-start mt-4 bg-primary text-white"
				type="submit"
				:disabled="!accepted || loading || successful"
				@click="() => (isValidated = true)"
				style="grid-column: 2; --btn-padding: 1rem 4rem; font-weight: 400; font-size: 1.2em"
			>
				Küldés
			</Button>

			<div v-if="successful" class="absolute inset-0 bg-white bg-opacity-90 items-center flex flex-col z-50 gap-8">
				<div class="text-primary text-4xl sticky top-32 h-fit">
					Köszönjük! Rendelését rögzítettük.<br />
					Kollégáink hamarosan jelentkeznek.
				</div>
				<RouterLink :to="{ name: 'landing-page' }" class="underline text-lg"> Vissza a főoldalra </RouterLink>
			</div>
		</form>
	</Article>
</template>
<style lang="scss">
form#order-form {
	display: grid;
	grid-template-columns: auto 500px;
	// @apply lg:grid-cols-1;

	align-items: baseline;
	@apply lg:flex lg:flex-col lg:items-stretch;

	// .input {
	// @apply lg:self-stretch;
	// }

	width: fit-content;
	gap: 1rem 2rem;

	label {
		@apply text-lg;
		@apply font-semibold;
	}

	h6.section-header {
		@apply border-b-2 border-current;
		@apply text-primary text-3xl;
		@apply pb-2 px-5;
		// @apply bg-neutral-100;
		grid-column: 1 / -1;
	}

	.scroller-bookmarks {
		@apply absolute;
		@apply flex flex-col;
		white-space: nowrap;
		left: 100%;
		top: 0;
	}
}
</style>
