<script setup lang="ts">
import { useRouter } from "vue-router"
import NavBarLink from "./NavBarLink.vue"
import NavBarUserInfo from "./NavBarUserInfo.vue"
import Icon from "../ui/Icon.vue"

const routes = useRouter()
	.getRoutes()
	.filter(r => r.meta.navbar)
</script>
<template>
	<menu id="navmenu">
		<ul class="navmenu-list">
			<template v-for="rt in routes">
				<RouterLink :to="{ name: rt.name }" class="navmenu-item">
					{{ rt.meta.navbar?.name }}
				</RouterLink>
				<ul v-if="rt.meta.navbar?.menu" class="navmenu-list">
					<RouterLink v-for="menuitem in rt.meta.navbar.menu" :to="menuitem.to" class="navmenu-item">
						{{ menuitem.label }}
					</RouterLink>
				</ul>
			</template>
		</ul>
	</menu>
</template>
<style lang="scss">
#navmenu {
	@apply bg-black bg-opacity-95;
	@apply hidden lg:block fixed;
	@apply text-white;
	@apply overflow-auto;
	height: 100%;
	width: 100%;
	z-index: 200;
}

.navmenu-list {
	@apply flex flex-col gap-2 px-8;

	// ul {
	// 	@apply pl-6 flex flex-col gap-2;
	// }
}

.navmenu-item {
	@apply py-3 text-lg;

	// &:not(:last-child) {
	// @apply border-b border-white border-opacity-20;
	// }
}
</style>
