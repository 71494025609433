<script setup lang="ts"></script>
<template>
	<svg
		version="1.0"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 386.000000 502.000000"
		preserveAspectRatio="xMidYMid meet"
	>
		<g transform="translate(0.000000,502.000000) scale(0.100000,-0.100000)" stroke="none">
			<path
				d="M2472 4708 c-109 -110 -132 -138 -132 -162 0 -25 25 -55 152 -183
l152 -153 68 0 69 0 -23 -30 c-16 -23 -30 -30 -54 -30 -83 0 -175 -33 -229
-82 -19 -18 -18 -20 72 -111 84 -85 90 -94 82 -120 -17 -60 18 -100 86 -96 45
3 47 2 126 -76 l80 -80 25 30 c40 48 65 115 71 187 5 55 10 70 28 82 13 8 25
12 29 9 3 -4 6 -32 6 -63 l0 -56 153 -153 c84 -83 159 -156 167 -161 40 -26
63 -12 197 124 108 109 133 140 133 163 0 23 -30 59 -157 191 l-158 162 -74 2
-74 3 59 59 c81 80 94 102 94 162 0 58 -16 85 -104 168 -60 56 -99 71 -159 59
-19 -4 -59 -33 -109 -81 l-78 -75 0 76 0 75 -152 146 c-129 123 -157 146 -183
146 -27 0 -51 -20 -163 -132z m408 -282 c0 -54 -3 -63 -32 -93 -29 -30 -37
-33 -92 -33 l-61 0 -122 122 -123 123 92 92 93 93 122 -122 123 -123 0 -59z
m607 -548 c68 -68 123 -125 123 -129 0 -8 -170 -179 -178 -179 -4 0 -64 57
-134 127 l-128 128 0 59 c0 51 4 64 27 88 24 25 33 28 97 28 l71 0 122 -122z"
			/>
			<path
				d="M1739 4014 c-8 -9 -9 -38 -4 -91 4 -43 8 -80 10 -83 2 -3 6 -29 10
-59 4 -29 13 -72 20 -95 39 -116 64 -177 93 -229 18 -31 32 -60 32 -64 0 -5
12 -21 27 -38 15 -16 28 -35 30 -43 2 -15 66 -89 91 -105 6 -4 12 -14 12 -21
0 -8 17 -28 38 -45 87 -71 116 -97 135 -118 11 -13 25 -23 30 -23 4 0 27 -13
50 -28 23 -16 66 -41 96 -56 30 -16 62 -32 70 -37 80 -44 329 -99 448 -99 l43
0 0 104 0 103 -84 6 c-85 6 -243 41 -260 58 -6 5 -18 9 -29 9 -34 0 -171 74
-257 139 -75 56 -160 135 -160 148 0 3 -16 24 -35 47 -19 24 -35 48 -35 55 0
6 -4 11 -9 11 -5 0 -14 15 -21 33 -8 17 -19 39 -27 48 -7 8 -31 71 -54 140
-33 101 -42 143 -46 227 -4 81 -9 105 -22 111 -28 14 -179 11 -192 -5z"
			/>
			<path
				d="M1280 3923 c0 -99 27 -255 68 -394 24 -79 90 -248 107 -269 6 -8 22
-37 35 -65 14 -27 35 -61 47 -75 13 -14 23 -29 23 -34 0 -5 16 -28 35 -52 19
-23 35 -46 35 -51 0 -4 11 -19 24 -33 14 -14 67 -69 119 -122 52 -54 98 -98
102 -98 4 0 19 -12 34 -27 15 -16 37 -35 50 -43 13 -8 36 -24 50 -35 46 -36
140 -95 152 -95 6 0 24 -10 41 -21 47 -34 271 -117 359 -134 12 -3 36 -9 53
-14 45 -15 235 -41 295 -41 61 0 63 5 59 130 l-3 85 -35 1 c-121 4 -349 43
-431 73 -30 10 -70 25 -89 32 -40 14 -108 45 -130 59 -8 6 -26 15 -40 20 -36
15 -67 34 -89 54 -11 10 -26 18 -34 20 -8 1 -32 17 -53 35 -47 40 -254 241
-254 247 0 2 -16 25 -35 51 -61 83 -71 99 -91 143 -9 19 -22 42 -28 50 -7 8
-23 40 -35 70 -12 30 -26 64 -30 75 -21 48 -45 123 -53 160 -28 137 -37 197
-46 305 l-7 85 -102 3 -103 3 0 -98z"
			/>
			<path
				d="M2165 4008 c-5 -13 9 -163 18 -203 9 -38 63 -166 77 -183 8 -9 22
-30 30 -46 15 -28 101 -128 148 -170 58 -53 70 -62 98 -76 16 -8 38 -23 49
-33 11 -10 31 -20 45 -24 14 -3 31 -10 38 -16 6 -5 12 -6 12 -2 0 4 10 1 23
-7 32 -21 100 -36 190 -42 l77 -6 0 104 0 103 -67 7 c-103 11 -268 71 -280
103 -3 7 -11 13 -17 13 -18 0 -135 127 -154 166 -8 19 -19 34 -24 34 -4 0 -8
8 -8 18 0 10 -9 42 -19 72 -11 30 -23 84 -27 120 -4 36 -11 68 -17 72 -20 12
-187 9 -192 -4z"
			/>
			<path
				d="M1145 2925 c-151 -42 -304 -163 -363 -287 -39 -82 -62 -171 -62 -237
l0 -51 220 0 c121 0 220 -2 220 -5 0 -3 -15 -36 -34 -74 -18 -37 -39 -86 -46
-107 -7 -21 -34 -82 -60 -134 -68 -140 -90 -186 -90 -195 0 -4 -14 -38 -31
-74 -17 -36 -40 -86 -51 -111 -12 -25 -31 -65 -43 -90 -13 -25 -26 -56 -30
-70 -4 -14 -36 -83 -70 -155 -35 -71 -66 -141 -70 -155 -4 -14 -35 -83 -70
-155 -34 -71 -75 -161 -90 -200 -16 -38 -42 -94 -57 -124 -16 -29 -45 -92 -65
-140 -64 -155 -163 -364 -175 -369 -4 -2 -8 -16 -8 -31 0 -23 8 -31 45 -50 24
-12 59 -25 77 -27 29 -5 35 -2 51 25 10 17 25 31 34 31 13 0 67 25 165 76 14
8 32 14 40 14 7 0 76 32 153 71 77 38 160 77 185 85 25 9 104 44 175 79 192
93 201 95 255 76 25 -9 101 -44 170 -77 69 -33 141 -66 160 -74 19 -7 103 -46
186 -86 83 -41 156 -74 163 -74 7 0 25 -7 39 -15 15 -8 64 -31 109 -52 50 -23
87 -47 95 -61 10 -20 18 -23 46 -18 18 3 50 13 70 23 55 27 53 52 -13 191 -31
64 -73 155 -92 202 -19 47 -61 139 -92 205 -32 66 -64 136 -71 155 -7 19 -41
94 -75 165 -35 72 -66 141 -70 155 -4 14 -34 79 -66 146 -32 66 -68 145 -79
174 -12 30 -42 97 -67 149 -73 155 -83 177 -83 186 0 9 -21 54 -90 195 -26 52
-53 113 -60 134 -7 21 -28 70 -46 107 -19 38 -34 71 -34 74 0 3 92 5 205 5
l205 0 0 51 c0 77 -36 204 -77 268 -76 120 -213 220 -349 255 -75 19 -220 20
-289 1z m193 -762 c16 -32 35 -76 42 -98 7 -21 23 -60 35 -85 13 -25 37 -76
55 -115 18 -38 39 -85 47 -102 20 -43 13 -58 -47 -95 -27 -17 -50 -35 -50 -39
0 -5 -7 -9 -15 -9 -8 0 -15 -4 -15 -9 0 -5 -19 -20 -42 -33 l-43 -24 -42 24
c-24 13 -43 28 -43 33 0 5 -4 9 -10 9 -5 0 -18 7 -27 15 -10 8 -37 28 -60 45
-44 31 -51 47 -33 78 5 9 28 58 51 107 22 50 52 113 65 141 13 28 24 54 24 58
0 11 69 156 74 156 3 0 18 -26 34 -57z m-328 -623 c0 -5 5 -10 11 -10 6 0 16
-6 23 -12 12 -12 26 -23 91 -72 11 -9 15 -16 8 -16 -6 0 -51 -31 -100 -69 -49
-37 -103 -76 -121 -85 -17 -9 -32 -21 -32 -26 0 -6 -7 -10 -15 -10 -16 0 -21
25 -6 34 10 6 61 112 61 126 0 16 65 150 73 150 4 0 7 -4 7 -10z m638 -61 c17
-38 32 -74 32 -79 0 -14 51 -120 61 -126 15 -9 10 -34 -6 -34 -8 0 -15 4 -15
10 0 5 -15 17 -32 26 -31 16 -137 92 -148 105 -3 4 -22 18 -42 32 l-38 25 28
18 c15 10 32 22 38 28 30 28 55 46 64 46 6 0 10 5 10 10 0 26 18 3 48 -61z
m-299 -183 c21 -13 80 -56 131 -95 51 -39 96 -71 100 -71 4 0 51 -34 104 -75
l98 -74 -156 -76 c-86 -41 -161 -75 -167 -75 -6 0 -42 -16 -80 -35 -81 -41
-82 -41 -117 -17 -15 10 -47 26 -72 36 -25 10 -54 22 -65 26 -11 5 -82 38
-158 74 l-139 66 89 68 c48 38 99 75 113 82 14 7 61 41 105 75 87 67 155 113
168 114 4 1 25 -10 46 -23z m-486 -529 c73 -35 153 -72 179 -82 26 -10 45 -21
42 -26 -3 -5 -9 -9 -15 -9 -9 0 -86 -36 -124 -58 -32 -18 -128 -62 -137 -62
-4 0 -36 -13 -70 -29 -35 -16 -70 -31 -78 -35 -8 -3 -22 -10 -30 -15 -24 -15
-145 -71 -152 -71 -12 0 -7 14 120 282 18 37 32 72 32 78 0 6 4 18 9 28 5 9
15 30 22 45 18 38 35 34 202 -46z m1086 46 c8 -16 17 -36 22 -45 5 -10 9 -22
9 -28 0 -6 14 -41 32 -78 127 -268 132 -282 120 -282 -4 0 -34 13 -67 29 -145
71 -254 121 -262 121 -10 0 -105 43 -138 62 -38 22 -115 58 -124 58 -6 0 -12
4 -15 9 -3 5 16 16 42 26 26 10 106 46 177 80 185 89 185 89 204 48z"
			/>
		</g>
	</svg>
</template>
<style lang="scss"></style>
