<script setup lang="ts">
import { ref } from "vue"
import type { SelectOption } from "./Select.vue"
const props = defineProps<{
	options: SelectOption[]
	class?: string
	modelValue: string | number | null
	name: string
	required?: boolean
}>()

const emit = defineEmits(["update:modelValue"])

// const selectedValue = ref(props.modelValue) as any

function updateValue(newValue: any) {
	console.log(innerValue.value)
	emit("update:modelValue", innerValue.value)
}

const innerValue = ref(props.modelValue)
</script>
<template>
	<fieldset class="input-radio-group">
		<template v-for="(opt, i) in options" :key="i">
			<!-- <fieldset > -->
			<label class="radio-group-item">
				<input
					type="radio"
					:value="opt.value"
					:name="name"
					:required="required"
					v-model="innerValue"
					@change="updateValue"
				/>
				<span class="radio-group-label">{{ opt.label }}</span>
			</label>
			<!-- </fieldset> -->
		</template>
	</fieldset>
</template>
<style lang="scss">
.input-radio-group {
	@apply flex flex-col gap-1;
}

.radio-group-item {
	@apply flex flex-row gap-4 items-baseline;
	cursor: pointer;
}

.radio-group-label {
	@apply font-normal;
}
</style>
