<script setup lang="ts">
defineProps<{
	modelValue: string | number
	error?: boolean
	style?: any
	is?: any
	// placeholder?: string
}>()

const emit = defineEmits(["update:modelValue"])
const updateValue = (event: Event) => {
	emit("update:modelValue", (event.target as HTMLInputElement)?.value)
}
</script>
<template>
	<fieldset class="input" :class="{ error }" :style="style">
		<span v-if="$slots.prefix" class="input-adornment">
			<slot name="prefix" />
		</span>
		<component :is="is ?? 'input'" class="input-content" :value="modelValue" v-bind="$attrs" @input="updateValue" />
		<span v-if="$slots.suffix" class="input-adornment">
			<slot name="suffix" />
		</span>
		<span class="input-button" v-if="$slots.button">
			<slot name="button" />
		</span>
	</fieldset>
</template>
<style lang="scss">
form.validated {
	.input {
		&:invalid {
			@apply border-red-500;
			@apply bg-red-500 bg-opacity-20;
		}
		// &:valid {}
	}
}

.input {
	@apply flex flex-row items-center;
	@apply box-border;
	@apply transition-all;
	@apply border-current border-neutral-800 border-2;
	@apply border-opacity-40;
	@apply rounded-full;
	overflow: hidden;

	--padding: 0.5rem 1rem;

	&:hover {
		@apply border-opacity-60;
	}

	&:focus-within {
		@apply border-opacity-100;
	}

	textarea {
		resize: none;
	}

	.input-content {
		@apply bg-transparent;
		position: relative;
		// @apply text-neutral-900;
		padding: var(--padding);
		flex-grow: 1;
		appearance: none;
		outline: none;
		width: var(--input-width);

		&::placeholder,
		&.placeholder {
			@apply text-neutral-800 text-opacity-50;
			// opacity: 80%;
		}
		// &.placeholder {
		// }

		&[disabled] {
			opacity: 0.5;
		}
	}

	.input-adornment {
		@apply text-opacity-80 text-inherit;
		@apply border-inherit;
		@apply flex items-center justify-center;
		padding: var(--padding);
	}

	&:focus-within .input-adornment {
		@apply text-opacity-100 text-primary;
	}
}

.input-wrapper {
	gap: 3px;

	.input {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.input + .button {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}

.input-wrapper {
	@apply flex items-stretch;
}
</style>
