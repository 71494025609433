<script setup lang="ts">
import { RouterView } from "vue-router"
import NavBar from "./components/app/NavBar.vue"
import StaticFooter from "./components/app/StaticFooter.vue"
import NavBarMobile from "./components/app/NavBarMobile.vue"
import Banner from "./components/app/Banner.vue"
import { CMS } from "@/api/cms"
import { useHead } from "@unhead/vue"
import { onMounted, ref } from "vue"

useHead({
	title: "Corrigo"
})
</script>
<template>
	<Suspense>
		<RouterView :key="'news'" v-slot="{ Component, route }">
			<div :id="(route.meta.rootId as string) ?? 'wrapper'">
				<Banner>
					<template>fug</template>
				</Banner>
				<NavBar :variant="route.meta.navbarVariant" />
				<NavBarMobile v-if="$route.query.navmenu" />
				<component :is="Component" />
				<StaticFooter :showSplash="Boolean(route.meta.splash)" v-if="route.meta.hideFooter != true" />
			</div>
		</RouterView>
	</Suspense>
</template>

<style lang="scss">
/* @import "@/assets/base.css"; */
@tailwind base;
@tailwind components;
@tailwind utilities;

h6 {
	@apply text-lg;
}

h5 {
	@apply text-xl;
}

h4 {
	@apply text-3xl;
	font-weight: 600;
}

h3 {
	@apply text-5xl;
	font-weight: 600;
}

div#app {
	position: absolute;
	inset: 0;
	// background-color: white;
	// height: 100vh;
	// display: flex;
	// flex-direction: column;
	// flex-grow: 0;
}

div#wrapper {
	@apply flex flex-col;
	// @apply bg-white;
}

:root {
	--c-primary: 57 179 75;
	--c-primary-d: 43 136 57;

	--c-green: #39b34b;
	--c-blue: 65 155 211;
	--panel-img-w: 500;
}

html {
	font-family: "Lexend", "Segoe UI", sans-serif;
	@apply bg-neutral-800;
}
</style>
