import { createRouter, createWebHistory, RouterView } from "vue-router"
import { defineAsyncComponent } from "vue"

import HomeViewVue from "@/views/HomeView.vue"
import AboutPage from "@/pages/AboutPage.vue"
import ServicesPage from "@/pages/ServicesPage.vue"
import InfoPage from "@/pages/InfoPage.vue"
import Article from "@/pages/Article.vue"
import { STATIC_ARTICLES } from "@/pages/articles"
import OrderPageVue from "@/pages/OrderPage/OrderPage.vue"
// import Button from '@/components/ui/Button.vue'

import CorrigoNetworkVue from "@/pages/CorrigoNetwork.vue"
import StationInfoVue from "@/components/app/network-map/StationInfo.vue"

import CertFrame from "@/components/app/CertFrame.vue"
import CompanyInformationVue from "@/pages/CompanyInformation.vue"
import GisDayVue from "@/pages/static/GisDay.vue"
import PiknikVue from "../pages/static/Piknik.vue"

import CmsArticle from "@/components/cms/cms-article.vue"
import CmsArticleList from "@/components/cms/cms-article-list.vue"

import NewsPage from "@/pages/news.vue"
import EventsPage from "@/pages/events.vue"
// const CorrigoNetworkVue = defineAsyncComponent(() => ))

declare module "vue-router" {
	interface RouteMeta {
		navbarVariant?: any
		navbar?: {
			name: string
			index?: number
			menu?: {
				label: string
				to: RouteLocationRaw
			}[]
			as?: any
		}
		hideFooter?: boolean
		rootId?: string
	}
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		// console.log(to)
		if (to.hash) {
			// console.log(to.hash)
			return {
				el: to.hash
			}
		}
	},
	routes: [
		{
			name: "landing-page",
			path: "/",
			component: HomeViewVue,
			meta: {
				splash: true
				// navbarVariant: "transparent"
			}
		},
		{
			name: "news",
			path: "/news",
			component: NewsPage,
			props: { type: "news", key: "news" },
			meta: {
				navbar: {
					name: "Hírek",
					index: 0
				}
			}
		},

		{
			name: "article",
			path: "/articles/:articleId/:alias?",
			component: CmsArticle,
			props: true
		},

		{
			name: "about",
			path: "/about",
			component: AboutPage,
			meta: {
				navbar: {
					name: "Rólunk",
					index: 0
				}
			}
		},
		{
			path: "/gis-day",
			redirect: {
				path: "/articles/gis-day"
			}
		},
		{
			path: "/piknik",
			redirect: {
				path: "/articles/piknik"
			},
			meta: {
				hideFooter: true,
				hideBanner: true
			}
		},
		// {
		// 	name: 'news',
		// 	path: '/news',
		// 	component: NewsViewVue,
		// 	meta: {
		// 		navbar: {
		// 			name: 'Hírek',
		// 			index: 10
		// 		},
		// 		splash: true,
		// 	}
		// },
		{
			name: "services",
			path: "/services",
			component: ServicesPage,
			meta: {
				navbar: {
					name: "Szolgáltatásaink",
					index: 20,
					menu: [
						{
							label: "Hogyan működik?",
							to: { name: "srv-rtk" }
						},
						{
							label: "Geodézia",
							to: { name: "srv-geod" }
						},
						{
							label: "Mezőgazdaság",
							to: { name: "srv-agro" }
						},
						{
							label: "Önvezető járművek, drónok",
							to: { name: "srv-drones" }
						}
					]
				}
			}
		},
		{
			name: "articles",
			path: "/articles",
			component: RouterView,
			children: [
				{
					path: "gis-day",
					name: "gis-day",
					component: GisDayVue
				},
				{
					path: "piknik",
					name: "piknik",
					component: PiknikVue
				},
				{
					path: "hogyan_mukodik",
					name: "srv-rtk",
					component: InfoPage
				},
				...STATIC_ARTICLES.map(item => {
					return {
						path: item.url!,
						name: item.routeName!,
						component: Article,
						// meta: {
						// 	navbarVariant: 'transparent'
						// },
						props: {
							article: item
						}
					}
				})
			]
		},
		{
			name: "network",
			path: "/network",
			component: CorrigoNetworkVue,
			meta: {
				navbar: {
					name: "Corrigo hálózat",
					index: 30,
					menu: [
						{
							label: "Bázisállomások",
							to: {
								path: "/network",
								hash: "#network-map"
							}
						},
						{
							label: "I95 index",
							to: {
								path: "/network",
								hash: "#i95"
							}
						},
						{
							label: "IRIM/GRIM",
							to: {
								path: "/network",
								hash: "#residual"
							}
						}
					]
				},
				rootId: "full-size-container",
				hideFooter: true
			},
			children: [
				{
					name: "station-info",
					path: "stations/:stationCode",
					component: StationInfoVue,
					props: true
				}
			]
		},
		{
			name: "order",
			path: "/megrendeles",
			component: OrderPageVue,
			meta: {
				// navbarVariant: 'transparent',
				navbar: {
					name: "Megrendelés",
					index: 40
				}
			}
		},
		// {
		// 	name: 'login-screen',
		// 	path: '/login',
		// 	component: LoginPage,
		// 	meta: {
		// 		navbarVariant: 'transparent',
		// 		hideFooter: true,
		// 		rootId: 'login-screen'
		// 	}
		// },
		{
			name: "login-info-page",
			path: "/login",
			component: CertFrame,
			meta: {
				// navbarVariant: 'transparent',
				hideFooter: true,
				rootId: "full-size-container",
				navbar: {
					name: "Bejelentkezés",
					index: 70
				}
			}
		},
		{
			name: "company-info",
			path: "/ceginfo",
			component: CompanyInformationVue
		}
		// {
		// 	name: 'contact',
		// 	path: '/contact',
		// 	component: HomeViewVue,
		// 	meta: {
		// 		navbar: {
		// 			name: 'Kapcsolat',
		// 			index: 50
		// 		}
		// 	}
		// },
	]
})

export default router
