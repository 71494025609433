<script setup lang="ts">
import Panel from "./Panel.vue"
import Button from "../ui/Button.vue"
import Icon from "../ui/Icon.vue"
import NewsPanelItem from "./NewsPanelItem.vue"
import HeadlessPaginator from "../ui/Paginator/headless-paginator.vue"
import { CMS } from "@/api/cms"
import type { StrapiObject } from "@/api/types-strapi"
import type { Article } from "@/api/types-model"
import ArticlePreview from "../cms/article-preview.vue"

const { data, meta } = await CMS.getArticles()
</script>
<template>
	<Panel
		bg="/img/bg01.jpg"
		overlay="rgba(50,50,50,.4)"
		style="background-position: -600px 80px; --padding: 80px 100px 260px 100px"
		wrapperClass="flex gap-32"
	>
		<!-- <NewsProvider v-slot="{ newsBoards: items }" type="news"> -->
		<HeadlessPaginator :defaultPageSize="3" :data="data" v-slot="{ dataSlice, nextPage, prevPage }">
			<div>
				<h2 class="color-primary mb-8">Híreink</h2>
				<div class="flex gap-8 color-primary px-4" style="--btn-padding: 1em">
					<Button @click="prevPage">
						<Icon icon="chevron-left" />
					</Button>

					<Button @click="nextPage">
						<Icon icon="chevron-right" />
					</Button>
				</div>
			</div>
			<ul class="flex gap-12 text-primary" style="--btn-padding: 0.32em 0.8em">
				<!-- <div v-for="{id, attributes: article} in (dataSlice as StrapiObject<Article>[])">
					{{ article.indexImage.data.attributes.formats.thumbnail }}
				</div> -->
				<article-preview
					v-for="{id, attributes: article} in (dataSlice as StrapiObject<Article>[])"
					:key="id"
					:article-id="id"
					:type="article.type"
					:title="article.title"
					:publishedAt="article.publishedAt"
					:summary="article.summary"
					:img-url="article.indexImage.data.attributes.formats?.small?.url"
				/>
			</ul>
		</HeadlessPaginator>
		<!-- </NewsProvider> -->
	</Panel>
</template>
<style lang="scss"></style>
