import { Station } from "../../../api/types-model"
import { StrapiResponse } from "../../../api/types-strapi"
import proj4 from "proj4"

/**
 * 	from:	EPSG:4936
 * 	to: 	EPSG:4326
 */

proj4.defs("EPSG:4936", "+proj=geocent +ellps=GRS80 +units=m +no_defs +type=crs")
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs +type=crs")
proj4.defs(
	"EPSG:3857",
	"+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs"
)

export default function ({ data }: StrapiResponse<Station[]>) {
	const features = data.map(({ attributes, id }) => {
		return {
			id,
			type: "Feature",
			geometry: {
				type: "point",
				coordinates: proj4("EPSG:4936", "EPSG:3857", parseCoordinatesString(attributes.posXyz))
			},
			properties: attributes
		}
	})

	const bbox = [
		Math.min(...features.map(f => f.geometry.coordinates[0])),
		Math.min(...features.map(f => f.geometry.coordinates[1])),
		Math.max(...features.map(f => f.geometry.coordinates[0])),
		Math.max(...features.map(f => f.geometry.coordinates[1]))
	]

	return {
		type: "FeatureCollection",
		features,
		bbox
	}
}

function parseCoordinatesString(str: string) {
	// const value1 = str.split(";").map(coords => {
	// 	const [_, num] = coords.split("=")
	// 	return Number(num.replace(",", "."))
	// })

	let x = 0,
		y = 0,
		z = 0

	for (const coords of str.split(";")) {
		const [dim, value] = coords.split("=")

		const parsedValue = Number(value.replace(",", "."))
		if (dim == "X") x = parsedValue
		if (dim == "Y") y = parsedValue
		if (dim == "Z") z = parsedValue
	}

	const value2 = [x, y, z]

	// console.log(value1.toString(), "\n", value2.toString())

	return value2
}
