import { createApp } from "vue"
import { createPinia } from "pinia"
import { createHead } from "@unhead/vue"
// import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
// import { Vue3Mq } from "vue3-mq";

import "vue3-openlayers/dist/vue3-openlayers.css"
import OpenLayersMap from "vue3-openlayers"

import App from "./App.vue"
import router from "./router"
import dayjs from "dayjs"
import "dayjs/locale/hu"
dayjs.locale("hu")
// import dayjs from "dayjs"

// const OpenLayersMap =

// import OpenLayersMap from

const app = createApp(App)
const head = createHead()

app.use(head)
app.use(createPinia())
app.use(OpenLayersMap)
// app.use(dayjs)
// app.component('OpenLayersMap', defineAsyncComponent( () => import('vue3-openlayers')))

// app.use(autoAnimatePlugin)
app.use(router)

app.mount("#app")
