<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import NavBarLink from "./NavBarLink.vue"
import NavBarUserInfo from "./NavBarUserInfo.vue"
import NavBarMobile from "./NavBarMobile.vue"
import Icon from "../ui/Icon.vue"
import Brand from "./Brand.vue"

// import { useMq } from "vue3-mq";

// const mq = useMq()

const router = useRouter()

const props = defineProps<{
	variant?: any
}>()

const routes = useRouter()
	.getRoutes()
	.filter(r => r.meta.navbar)

const menuOpen = ref(false)

function toggle() {
	const cr = router.currentRoute.value
	if (!cr.query.navmenu) {
		router.replace({ ...cr, query: { navmenu: "true" } })
	} else {
		router.replace({ ...cr, query: {} })
	}
}
</script>
<template>
	<nav id="navbar" :class="{ 'navbar-transparent': variant === 'transparent' }">
		<router-link as="a" class="brand" :to="{ name: 'landing-page' }"></router-link>
		<!-- <Brand/> -->
		<div class="navbar-content">
			<template v-for="route in routes">
				<RouterLink v-if="route.meta!.navbar!.as" :to="{name: route.name!}">
					<component :is="route.meta!.navbar!.as" class="text-primary">
						{{ route.meta.navbar?.name }}
					</component>
				</RouterLink>
				<NavBarLink v-else :to="{name: route.name!}" :menu="(route.meta.navbar as any).menu">
					{{ route.meta.navbar?.name }}
				</NavBarLink>
			</template>
			<!-- <NavBarUserInfo /> -->
		</div>

		<button @click="toggle" class="hidden lg:block">
			<Icon :icon="$route.query.navmenu ? 'close' : 'bars'" size="32" />
		</button>
		<!-- <NavBarMobile/> -->
	</nav>
</template>
<style lang="scss">
#navbar {
	@apply flex justify-between items-center;
	@apply bg-black;
	// padding-block: 14px;
	padding-inline: 18px;
	@apply text-white;
	// @apply backdrop-filter backdrop-blur;
	// @apply shadow-lg;
	// position: absolute;
	z-index: 100;
	top: 0px;
	width: 100%;
	@apply overflow-y-visible overflow-x-clip;

	@apply lg:py-4;
	@apply relative xl:sticky;

	&.navbar-transparent {
		position: absolute;
		@apply bg-transparent;
		@apply 2xl:sticky 2xl:bg-black;
	}
}

.brand {
	background-image: url("/img/corrigo_white.png");
	height: 36px;
	width: 200px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center left;
}

.navbar-content {
	@apply flex items-center;
	// @apply gap-6;
	@apply lg:hidden;

	@apply text-xl;
	@apply font-bold;
	// @apply lg:
}
</style>
