<script setup lang="ts">
import { Popover, PopoverPanel, PopoverButton } from "@headlessui/vue"
import { computed } from "vue"

const props = defineProps<{
	color?: "primary" | "error" | "neutral"
	variant?: "filled" | "outlined" | "subdued" | "ghost" | "link"
	disabled?: boolean
	href?: string
	popoverPlacement?: "bottom" | "top"
	size?: "sm" | "md" | "lg" | "xl"
}>()

const element = computed(() => {
	return props.href && !props.disabled ? "a" : "button"
})

const emit = defineEmits(["click"])
</script>
<template>
	<button
		@click="
			() => {
				if (!disabled) emit('click')
			}
		"
		:disabled="disabled"
		class="button"
		:class="[variant, color, size]"
	>
		<slot name="prefix" />
		<span v-if="$slots.default"><slot /></span>
		<slot name="suffix" />
	</button>
</template>
<style lang="scss">
button.button
// button.button[type="submit"]
{
	padding: var(--btn-padding, 0.5em 1em);
	font-weight: 400;
	text-transform: lowercase;
	@apply flex items-center justify-center gap-2;
	@apply cursor-pointer;
	@apply rounded-full;
	@apply text-sm;
	@apply transition-all;
	@apply border;
	// @apply bg-black bg-opacity-20;

	border-color: currentColor;

	&.neutral {
	}

	&.primary {
		color: var(--c-green);

		&:hover {
			// @apply bg-primary-500;
		}
		&:active,
		&:focus,
		&:focus:hover {
			// @apply ring-primary-600 ring-opacity-40;
		}
	}
	&.subdued {
		@apply border-transparent;
		--bg-alpha: 20%;
		background-color: rgb(var(--color) / var(--bg-alpha));
		color: rgb(var(--color) / 100%);
		box-shadow: none;

		&:hover {
			--bg-alpha: 30%;
		}

		&:active {
			--bg-alpha: 40%;
			box-shadow: 0 0 0 2px rgb(var(--color) / 20%);
		}

		&[disabled] {
			@apply text-neutral-600 bg-neutral-600;
			@apply bg-opacity-20 hover:bg-opacity-20;
		}
	}
	&[disabled] {
		@apply text-neutral-500 opacity-50;
		@apply cursor-not-allowed;
	}

	&.lg {
		@apply text-lg;
		--btn-padding: 0.5rem 1rem;
	}

	&.xl {
		@apply text-xl;
		--btn-padding: 0.75rem 1.25rem;
	}
}
</style>
