<script setup lang="ts">
import { CMS } from "@/api/cms"
import articlePreview from "./article-preview.vue"

const { data, meta } = await CMS.getArticles()
</script>
<template>
	<ul class="articles-grid">
		<article-preview
			v-for="{ attributes: article, id } of data"
			:key="id"
			:article-id="id"
			:type="article.type"
			:title="article.title"
			:summary="article.summary"
			:img-url="article.indexImage.data.attributes.formats?.small?.url"
			:date="article.date"
		/>
	</ul>
</template>
<style lang="scss">
ul.articles-grid {
	--gutter: 20rem;
	@apply text-neutral-800;
	display: grid;
	flex-grow: 1;
	// grid-auto-flow: column;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: stretch;
	justify-content: center;
	justify-items: center;
	padding-inline: var(--gutter);
	// justify-content: start;
	gap: 2rem;
	padding-block: 3rem;
	// padding: 3rem;
	background-color: white;
	// @apply bg-neutral-100;
	// background: #ff00ff, url("/img/bg01.jpg");
	@media (max-width: 1535px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media (max-width: 1279px) {
		grid-template-columns: 1fr 1fr;
		--gutter: 64px;
	}
	@media (max-width: 1023px) {
		--gutter: 12px;
		grid-template-columns: 1fr;
	}

	@media (max-width: 559px) {
		--gutter: 4px;
		// --preview-h: 200px;
		--preview-w: 200px;
	}
}
</style>
