import { Article, Station } from "./types-model"
import { StrapiResponse } from "./types-strapi"

async function __fetch<T>(url: string) {
	const response = await fetch(url)
	const json = await response.json()
	return json as T
}

export class CMS {
	static async loadArticle(articleId: number) {
		return await __fetch<StrapiResponse<Article>>(`/api/articles/${articleId}?populate=*`)
	}

	static async getActiveBanner() {
		return await __fetch(`/api/banner?populate=*`)
	}

	static async getArticles(type?: "news" | "event") {
		return await __fetch<StrapiResponse<Article[]>>("/api/articles?populate=*&sort=date:desc")
		/* 	return await __fetch<StrapiResponse<Article[]>>(
			(type ? `/api/articles?filters[type][$eq]=${type}&populate=*` : `/api/articles?populate=*`) +
				"&sort=publishedAt:desc"
		) */
	}

	static async getStations() {
		return await __fetch<StrapiResponse<Station[]>>("/api/stations/")
	}
}
