<script setup lang="ts">
import Article from "./Article.vue"
import DataList from "../components/ui/DataList.vue"

const data = {
	"Teljes név": "Infobex Informatikai és Szolgáltató Kft.",
	"Rövidített cégnév": "Infobex Kft.",
	Székhely: "6000 Kecskemét, Kisfái tanya 207/A.",
	"Telephely/levelezési cím": "6500 Baja, Szegedi út 121. fsz. 2.",
	Adószám: "23747405-2-03",
	Cégjegyzékszám: "03-09-123778",
	"E-mail": "info@infobex.hu",
	"Központi telefonszám": "+3679523160",
	"Bankszámla szám": "K&H Bank Zrt. 10402805-50526780-52561002"
}
</script>
<template>
	<Article
		:article="{
			title: 'Céginformáció',
			background: {
				//src: '/img/bg01.jpg',
				color: 'rgba(40 40 40 / 100%)'
			}
		}"
	>
		<ul class="border-list">
			<li v-for="([key, value], i) in Object.entries(data)">
				<strong>{{ key }}:</strong><br />{{ value }}
			</li>
		</ul>
	</Article>
</template>
<style lang="scss" scoped>
strong {
	min-width: 0r;
}
</style>
