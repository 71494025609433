<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { networkApi } from "@/stores/core"
import type View from "ol/View"
import type { FeatureCollection } from "@api/models"
import Icon from "../../ui/Icon.vue"
import MapMarker from "./MapMarker.vue"
import type { Extent } from "ol/extent"
import Interaction from "ol/interaction/Interaction"
import { CMS } from "../../../api/cms"
import { Station } from "../../../api/types-model"
import toGeojson from "./toGeojson"
// import type Map from "ol/Map"

const props = defineProps<{
	selected?: string
	stations: any
}>()

const viewRef = ref<View>()
const mapRef = ref<any>()

const ANIM_DURATION = 250

// networkApi.getStations().then(fc => {

const view = {
	projection: "EPSG:3857",
	zoom: 6,
	rotation: 0,
	center: [0, 0]
}

if (props.selected) {
	zoomToStation(props.selected)
}

function zoomToStation(code: string) {
	const f = props.stations.features.find(f => f.properties.stationCode == code)
	if (f) {
		viewRef.value?.animate({
			zoom: 9,
			center: f.geometry.coordinates as [number, number],
			duration: ANIM_DURATION
		})
	}
}

watch(
	() => props.selected,
	newV => {
		if (newV) {
			zoomToStation(newV)
		} else {
			viewRef.value?.fit(props.stations.bbox as Extent, {
				padding: [100, 100, 100, 100],
				duration: ANIM_DURATION
			})
		}
	}
)

onMounted(() => {
	let piss = mapRef.value.map //as Map

	piss.getInteractions().forEach(i => {
		i.setActive(false)
	})

	viewRef.value?.fit(props.stations.bbox as Extent, { padding: [100, 100, 100, 100] })

	// piss.getInteractions().forEach( int => {
	// 	piss.removeInteraction(int)
	// })í
})
</script>
<template>
	<ol-map
		id="network-map"
		:class="{ 'w-selection': selected }"
		:loadTilesWhileAnimating="false"
		:loadTilesWhileInteracting="true"
		ref="mapRef"
	>
		<ol-attribution-control />
		<ol-view ref="viewRef" v-bind="view" />

		<ol-tile-layer v-if="stations?.bbox">
			<!-- <ol-source-osm/> -->
			<ol-source-xyz
				url="https://{1-4}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/jpg?apiKey=iiSrSP7tXkrW--hX8pOa7tLbQiw2PolyJzA7khXuqgU&lg=hun"
			/>
		</ol-tile-layer>

		<template v-for="feature in stations.features" :key="feature.id">
			<ol-overlay :position="feature.geometry.coordinates" positioning="center-center" :stopEvent="true">
				<RouterLink
					class="map-marker"
					activeClass="selected"
					:to="{
						name: 'station-info',
						params: { stationCode: feature.properties.stationCode }
					}"
				>
					<span class="map-marker-icon">
						<MapMarker width="40px" height="40px" fill="" class="xl:hidden" />
						<Icon icon="location-dot" size="24" class="hidden xl:block" />
						<!-- <Icon icon="satellite-dish" size="32" /> -->
						<!-- <Icon icon="signal-stream"  size="32" /> -->
						<!-- <span class="fa-beat-fade">FING</span> -->
						<!-- <Icon icon="poo" class="fa-bounce" size="32" /> -->
					</span>
					<span class="map-marker-text">
						{{ feature.properties.stationCode }}
					</span>
				</RouterLink>
				<!-- <Button color="primary"> fing </Button> -->
			</ol-overlay>
		</template>
		<div class="ol-attribution ol-unselectable ol-control ol-uncollapsible z-50" style="pointer-events: auto">
			<button type="button" aria-expanded="true" title="Attributions">
				<span class="ol-attribution-expand">i</span>
			</button>
			<ul>
				<li>© 2022 <a href="http://developer.here.com">HERE Technologies</a></li>
			</ul>
		</div>
	</ol-map>
</template>
<style lang="scss">
.map-marker-icon {
	@apply text-white;
	// @apply border-2 border-black;
	@apply bg-primary-dark;
	@apply rounded-full;
	@apply flex items-center justify-center;
	// @apply shadow-sm;
	@apply ring ring-primary ring-opacity-40;
	@apply transition-all;
	// @apply hover:scale-105;
	@apply hover:ring-opacity-100; // hover:bg-primary-dark;

	// @apply md:hidden;

	// @apply xl:p-2;
	@apply p-3 xl:p-1 lg:ring-0;

	z-index: 10;
}

#network-map.w-selection {
	.map-marker:not(.selected) .map-marker-icon {
		@apply bg-neutral-700;
		@apply ring-0;

		&:hover {
			@apply bg-primary-dark;
		}
	}
}

.map-marker {
	@apply flex  items-center justify-center gap-1;
	@apply flex-col;
	@apply cursor-pointer;
	@apply transition-all;
	&.selected {
		@apply scale-110;
		.map-marker-icon {
			@apply ring-opacity-100;
		}
	}
	// padding: 4px;
	// fill: ;
}

.map-marker-text {
	// @apply absolute;
	@apply font-bold;
	@apply text-neutral-900;
	bottom: -28px;
	@apply bg-neutral-200;
	@apply rounded-full;
	@apply border-current;
	@apply border-2;
	@apply text-sm;
	@apply lg:hidden;
	// @apply shadow;
	padding: 0 4px;
	line-height: normal;
	z-index: 5;
}
</style>
