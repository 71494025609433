<script setup lang="ts">
import { getPagination } from "@/components/ui/Paginator/paginate"
import { clamp } from "@/components/utils/clamp"
import { computed, ref } from "vue"

const props = withDefaults(
	defineProps<{
		data: unknown[]
		defaultPageSize: number
		defaultPage: number
	}>(),
	{
		defaultPage: 0
	}
)

const currentPage = ref(props.defaultPage)
const pageSize = ref(props.defaultPageSize)

const config = computed(() => getPagination(props.data.length, pageSize.value, currentPage.value))

function goTo(pg: number) {
	currentPage.value = clamp(pg, 0, config.value.totalPages - 1)
}

function nextPage() {
	goTo(currentPage.value + 1)
}

function prevPage() {
	goTo(currentPage.value - 1)
}

const dataSlice = computed(() => props.data.slice(config.value.startIndex, config.value.endIndex))

defineExpose({
	currentPage,
	pageSize,
	config,
	dataSlice
})
</script>
<template>
	<slot
		v-bind="{
			startIndex: config.startIndex,
			endIndex: config.endIndex,
			totalPages: config.totalPages,
			currentPage: config.currentPage,
			dataSlice,
			pageSize,
			nextPage,
			prevPage,
			goTo
		}"
	/>
</template>
<!-- <style lang="scss"></style> -->
